import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalsComponent, Typography } from '../../../../Components';
import { ProgressBar } from '../../../../Components/ProgressBar';
import DonationAmount from './DonationAmount';
import CheckoutAuth from './CheckoutAuth';
import PaymentOption from './PaymentOption';
import DonationCompleted from './DonationCompleted';
import { useDispatch, useSelector } from 'react-redux';
import PaymentFailed from './PaymentFailed';
import { TotalAmount } from '../../../../Components/MyCartDrawer/TotalAmount';
import { amountValidationSchema } from '../../../../Utils/validation';
import currencies from "../../../../Utils/country-currencies.json"
import { useSearchParams } from 'react-router-dom';
import { updateCartItems } from '../../../../Redux/cartReducer';
let timeout = 0;

const CheckoutModal = ({ showModal, toggleModal, project, activeStep, hidePrevButton = false, cartType = "single", updateProjectOverviews = () => null, isAnonymous = false }) => {
    const dispatch =useDispatch()
    const [projectDetails, setProjectDetails] = useState(project || {});
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentStep, setCurrentStep] = useState(activeStep);
    const [isPaymentCompleted, setIsPaymentCompleted] = useState({
        status: false,
        donationIds: [],
        error: false,
        errorMessage: ""

    });
    let pageProps = { ...Object.fromEntries([...searchParams]) }

    const { cartItems } = useSelector(state => state?.cart) || { cartItems: [] }
    const { name = "", images = [], amount = 0, goalAmountCurrency = "" } = projectDetails || { project: "", images: [], amount: 0, goalAmountCurrency: "" }

    const [payment, setPayment] = useState({
        amount: null,
        currency: goalAmountCurrency,
        type: 'CUSTOM_AMOUNT',
        isRecurring: false,

    })

    const steps = [
        { id: '1', title: 'One' },
        { id: '2', title: 'Two' },
    ];

    const CheckoutStepper = useCallback(({ steps, currentStep }) => (
        <ol className={`flex items-center justify-between w-full`}>
            {!!steps?.length && steps?.map((step) => (
                <li
                    key={step.id}
                    className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-neutral-400 after:border-1 after:border-dashed after:inline-block last:after:hidden last:w-fit text-sm/4 font-bold ${currentStep >= step.id ? 'text-primary-300' : 'text-neutral-400'}`}>
                    <span className={`flex flex-col items-center justify-center bg-white rounded-full size-7 shrink-0 ring-1 ${currentStep >= step.id ? 'ring-primary-300 bg-primary-100' : 'ring-neutral-400'}`}>
                        <span>{step.id}</span>
                    </span>
                </li>
            ))}
        </ol>
    ), []);

    const updatePaymentStatus = (newStatus) => {
      
        setIsPaymentCompleted({ ...isPaymentCompleted, ...newStatus });
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            if(newStatus?.status && newStatus?.donationIds?.length){
                dispatch(updateCartItems([]))
                setSearchParams({ ...pageProps, refresh: true });
                localStorage.removeItem('cart')
            }
        }, 1000);

    }

    const renderStepContent = React.useCallback(() => {
        if (isPaymentCompleted?.status) {
            return <DonationCompleted isPaymentCompleted={isPaymentCompleted} />;
        }
        else if (isPaymentCompleted?.error) {
            return <PaymentFailed errorMessage={isPaymentCompleted?.errorMessage} toggleModal={toggleModal} />;
        }

        switch (currentStep) {
            case 1:
                return <DonationAmount isAnonymous={isAnonymous} payment={payment} schema={amountValidationSchema} currentStep={currentStep} toggleModal={toggleModal}
                    setCurrentStep={setCurrentStep} isPaymentCompleted={isPaymentCompleted} updatePaymentStatus={updatePaymentStatus}
                    setPayment={setPayment} project={project} />;
            case 2:
                return <CheckoutAuth payment={payment} hidePrevButton={hidePrevButton} isAnonymous={isAnonymous}
                    isPaymentCompleted={isPaymentCompleted} updatePaymentStatus={updatePaymentStatus}
                    schema={amountValidationSchema} currentStep={currentStep} toggleModal={toggleModal}
                    setCurrentStep={setCurrentStep} updateProjectOverviews={updateProjectOverviews}
                    setPayment={setPayment} project={project} />;
            case 3:
                return <PaymentOption payment={payment} isPaymentCompleted={isPaymentCompleted} updatePaymentStatus={updatePaymentStatus}
                    schema={amountValidationSchema}
                    currentStep={currentStep} toggleModal={toggleModal}
                    setCurrentStep={setCurrentStep}
                    setPayment={setPayment} project={project} />;
            default:
                return null;
        }
        //eslint-disable-next-line
    }, [project, payment, amountValidationSchema, currentStep, Object.values(isPaymentCompleted), toggleModal, updateProjectOverviews, isAnonymous])


    const handleCloseModal = () => {
        setIsPaymentCompleted({
            status: false,
            donationIds: [],
            error: false,
            errorMessage: ""
        })
        toggleModal();
    }

    useEffect(() => {
        setCurrentStep(activeStep);
        return () => {
            setCurrentStep(0);


        }
    }, [activeStep])

    useEffect(() => {
        if (JSON.stringify(projectDetails) !== JSON.stringify(project)) {
            setProjectDetails(project);
            setPayment({ ...payment, currency: project?.goalAmountCurrency })
        }


        //eslint-disable-next-line
    }, [project])




    return (
        <ModalsComponent
            showModal={showModal}
            toggleModal={handleCloseModal}
            content={
                <div className='flex flex-col gap-5 md:gap-7.5 mt-4 h-full'>
                    <div>
                        <div className='hidden md:flex'>
                            <CheckoutStepper steps={steps} currentStep={currentStep} />
                        </div>
                        <div className='md:hidden'>
                            <ProgressBar
                                progress={`${(((currentStep) / steps.length) * 100).toFixed(2)}%`}
                                className="justify-center flex-1 w-full"
                                labelTextClassName="text-center !text-sm/4 !font-bold !text-primary-300"
                                subLabel={`${currentStep}/${steps.length}`}
                                textLabel=""
                                progressClassName="hidden"
                            />
                        </div>
                    </div>

                    {!isPaymentCompleted?.status && <>
                        {cartType === 'multiple' ?
                            <div className='max-h-[300px] overflow-scroll space-y-2'>
                                {!!cartItems?.length && cartItems?.map(item =>
                                    <SingleCartItem key={item?.name} {...item} cartType={cartType} />
                                )}     </div>
                            : <SingleCartItem cartType={cartType} name={name} images={images} amount={amount} goalAmountCurrency={goalAmountCurrency} />
                        }
                    </>}
                    {!!cartItems?.length && <TotalAmount cartItems={cartItems} />}
                    {renderStepContent()}
                </div>
            }
        />
    );
};

CheckoutModal.propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
};

CheckoutModal.defaultProps = {
    showModal: false,
    toggleModal: () => { },
};

export default React.memo(CheckoutModal);

const SingleCartItem = ({ images, name, amount, cartType, currency }) => {

    const currencySymbol = (currencies?.length && currencies?.find(country => country?.currency?.code === currency)?.currency?.symbol) || currency

    return (<div className='flex items-center gap-4 p-4 border rounded-lg border-neutral-400'>
        <div>
            <img className='object-cover w-full rounded max-h-14 md:max-h-20'
                onError={
                    (e) => e.target.src = "/assets/images/no-image-available.svg"
                }
                src={images?.at(0)?.url || "/assets/images/no-image-available.svg"} alt="Project Cover" />
        </div>
        <div className='w-full space-y-3 md:pe-2'>
            <Typography variant="authText" className="!font-bold text-neutral-700" text="You are supporting" />
            <Typography variant="heading5" className="text-neutral-900 text-wrap max-md:text-heading-7" text={name} />
        </div>
        {cartType === "multiple" && <div className='flex justify-end w-4/5 text-right'>
            <Typography variant="heading5" className="text-primary-600 text-wrap max-md:text-heading-7" text={`${currencySymbol} ${amount}`} />
        </div>}
    </div>
    )
}